import React, { useState } from 'react';
import { Builder, withChildren } from '@builder.io/react';

import { useMediaQuery } from 'src/common/hooks/useMediaQuery';

import GlintsAppModal from '../sections/GlintsAppModal';

type BuilderProps = {
  url: string;
  openModal: boolean;
  children: React.ReactNode;
};

const ModalWrapper = ({ children, openModal, url }: BuilderProps) => {
  const isDesktop = useMediaQuery('(min-width: 991px)');
  const [showGlintsAppModal, setShowGlintsAppModal] = useState(false);

  const decideFlow = () => {
    if (openModal && isDesktop) {
      setShowGlintsAppModal(true);
    }
  };

  if (openModal && isDesktop)
    return (
      <React.Fragment>
        <div onClick={decideFlow}>{children}</div>
        <GlintsAppModal
          isOpen={showGlintsAppModal}
          onClose={() => setShowGlintsAppModal(false)}
        />
      </React.Fragment>
    );

  return (
    <a href={url || 'https://glints.com'} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

Builder.registerComponent(withChildren(ModalWrapper), {
  name: 'v2 Modal Wrapper',
  description: 'Wraps another component to open a link or modal',
  inputs: [
    {
      name: 'openModal',
      type: 'boolean',
      helperText: 'Enable to make nested component open the modal',
    },
    {
      name: 'url',
      type: 'text',
      helperText:
        'The URL to use if not opening modal. Will be ignored if "open modal" is enabled',
    },
  ],
});

export default ModalWrapper;
